<template>
  <div>
    <navbar
      @toggle-drawer="$refs.drawer.drawer = !$refs.drawer.drawer"
    ></navbar>
    <sidenav ref="drawer"></sidenav>
    <v-container
      ><v-row align-content="center" justify="center">
        <v-col cols="12" class="main_card_col">
          <v-card elevation="2">
            <v-card-title>
              <span class="ml-2">Calving Analytics</span>
              <v-spacer></v-spacer>
              <!-- <v-switch
                v-model="show_thumbnail"
                label="Thumbnail"
                dense
                hide-details
                class="mr-4"
              >
              </v-switch> -->
              <v-btn @click="refresh" class="mr-4">
                <v-icon>mdi-refresh</v-icon>
              </v-btn>

              <!-- <div class="mt-n4 table_search_field">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                >
                </v-text-field>
              </div> -->
              <CustomerFilter
                class="mt-n4"
                v-model="selected_customer"
                :disabled="loading"
              ></CustomerFilter>
            </v-card-title>
            <v-card-text class="text-center">
              <v-data-table
                :server-items-length="calving_alaytic_count"
                :headers="selected_header"
                :items="selected_table == 4 ? [] : calving_alaytics"
                :items-per-page="100"
                :class="`livestock_table ${
                  selected_table == 4 ? 'empty_table' : 'elevation-1'
                }`"
                hide-default-footer
                item-key="id"
                :options.sync="options"
                :sort-desc="true"
                no-data-text="No data available"
              >
                <template v-slot:top="{ pagination, options, updateOptions }">
                  <div
                    class="oc_table_header"
                    :style="
                      selected_table == 4 ? 'height: 52px; display: block;' : ''
                    "
                  >
                    <v-tabs
                      class="table_tabs animal_tabs pt-1"
                      v-model="selected_table"
                    >
                      <v-tab>General</v-tab>
                      <v-tab>Annotations</v-tab>
                      <v-tab>Checks</v-tab>
                      <v-tab>SMS</v-tab>
                      <v-tab>Analytics</v-tab>
                    </v-tabs>
                    <v-data-footer
                      v-if="selected_table != 4"
                      :pagination="pagination"
                      :options="options"
                      class="table_pagination"
                      @update:options="updateOptions"
                      :items-per-page-options="[50, 100, 200]"
                      items-per-page-text="$vuetify.dataTable.itemsPerPageText"
                    />
                    <v-menu
                      v-else
                      v-model="analytics_datetime_menu"
                      :close-on-content-click="false"
                      open-on-hover
                      offset-overflow
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <div
                          style="width: 180px; float: right; margin-top: 12px"
                          class=""
                        >
                          <v-text-field
                            v-bind="attrs"
                            v-on="on"
                            append-icon="mdi-calendar"
                            class="mr-2 assign_select assign_select_v font_400"
                            single-line
                            hide-details
                            dense
                            height="32"
                            full-width
                            outlined
                            readonly
                            rounded
                            v-model="analytics_start_date"
                            :label="
                              analytics_start_date
                                ? analytics_start_date
                                : 'Date'
                            "
                          >
                          </v-text-field>
                        </div>
                      </template>

                      <v-card height="316" width="280">
                        <v-row>
                          <v-col>
                            <v-date-picker
                              v-model="analytics_start_date"
                              flat
                              no-title
                              id="video_dp"
                              event-color="#ff9900"
                              :max="todays_date"
                            ></v-date-picker>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-menu>
                  </div>
                </template>
                <template v-slot:[`body.prepend`]>
                  <tr class="mb_hidden" v-if="selected_table == 0">
                    <td></td>
                    <td class="select_width align_left pl-2">
                      <v-menu
                        v-model="datetime_menu"
                        :close-on-content-click="false"
                        open-on-hover
                        offset-overflow
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <div style="width: 180px" class="">
                            <v-text-field
                              clearable
                              v-bind="attrs"
                              v-on="on"
                              append-icon="mdi-calendar"
                              class="mr-2 assign_select assign_select_v font_400"
                              single-line
                              hide-details
                              dense
                              height="32"
                              full-width
                              outlined
                              readonly
                              rounded
                              v-model="date"
                              :label="date ? date : 'Date'"
                            >
                            </v-text-field>
                          </div>
                        </template>

                        <v-card height="316" width="280">
                          <v-row>
                            <v-col>
                              <v-date-picker
                                v-model="date"
                                flat
                                no-title
                                id="video_dp"
                                event-color="#ff9900"
                                :max="todays_date"
                              ></v-date-picker>
                            </v-col>
                          </v-row>
                        </v-card>
                      </v-menu>
                    </td>
                    <td></td>
                    <td class="select_width align_left pl-2">
                      <CameraAutoComplete
                        :table="true"
                        :all="true"
                        v-model="selected_camera"
                        :owner="selected_customer"
                        :value="selected_camera"
                      ></CameraAutoComplete>
                    </td>
                    <td></td>
                    <td>
                      <v-select
                        hide-details
                        outlined
                        v-model="contraction_1_filter"
                        :items="['All', 'tail_up', 'tail_down']"
                        class="mr-2 assign_select assign_select_v font_400"
                        dense
                        rounded
                      />
                    </td>
                    <td>
                      <v-select
                        hide-details
                        outlined
                        v-model="contraction_2_filter"
                        :items="['All', 'tail_up', 'tail_down']"
                        class="mr-2 assign_select assign_select_v font_400"
                        dense
                        rounded
                      />
                    </td>
                    <td>
                      <v-select
                        hide-details
                        outlined
                        v-model="valid_event_filter"
                        :items="['All', true, false]"
                        class="mr-2 assign_select assign_select_v font_400"
                        dense
                        rounded
                      />
                    </td>
                    <td></td>
                  </tr>
                </template>
                <template v-slot:[`item.age`]="{ item }">
                  <span>{{ num_days(item.media_timestamp) }}</span>
                </template>
                <template v-slot:[`item.image`]="{ item, index }">
                  <v-img
                    class="ma-1 calving_alaytic_img"
                    style="border-radius: 12px"
                    aspect-ratio="1.7"
                    :src="cookie_bucket + item.s3_uri"
                    @click="open_image(item, index)"
                  />
                </template>
                <template v-slot:[`item.customer_id`]="{ item }">
                  <span v-if="item.customer">{{
                    (item.customer.first_name
                      ? item.customer.first_name + " "
                      : "") + item.customer.last_name
                  }}</span>
                </template>
                <template v-slot:[`item.camera_id`]="{ item }">
                  <span>{{ item.camera_id }}</span>
                </template>
                <template v-slot:[`item.media_timestamp`]="{ item }">
                  <span>{{
                    item.media_timestamp
                      .substring(0, item.media_timestamp.length - 3)
                      .replace("T", " ")
                  }}</span>
                </template>
              </v-data-table>

              <v-row v-if="selected_table == 4">
                <v-col cols="12">
                  <chart_summary_media
                    v-if="analytics_data"
                    :labels="analytics_data.labels"
                    :datasets="analytics_data.datasets_type"
                    :timeframe="'1day'"
                    title="By Type"
                  />
                </v-col>
                <v-col cols="12">
                  <chart_summary_media
                    v-if="analytics_data"
                    :labels="analytics_data.labels"
                    :datasets="analytics_data.datasets_customer"
                    :timeframe="'1day'"
                    title="By Customer"
                  />
                </v-col>
                <v-col cols="12">
                  <chart_summary_media
                    v-if="analytics_data"
                    :labels="analytics_data.labels"
                    :datasets="analytics_data.datasets_sent"
                    :timeframe="'1day'"
                    title="By Send Type"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <image-card :noFilter="true"></image-card>
  </div>
</template>

<script>
import navbar from "@/components/Navbar.vue";
import sidenav from "@/components/SideNav.vue";
import moment from "moment-timezone";
import CustomerFilter from "../components/CustomerFilter.vue";
import ImageCard from "../components/ImageCard.vue";
import CameraAutoComplete from "../components/CameraAutoComplete.vue";
import { eventBus } from "../main";
import chart_summary_media from "../components/summary_media/chart_summary_media.vue";

// @vuese
// @group Views
export default {
  components: {
    navbar,
    sidenav,
    CustomerFilter,
    ImageCard,
    CameraAutoComplete,
    chart_summary_media,
  },
  data() {
    return {
      search: "",
      loading: true,
      date: null,
      todays_date: moment().format("YYYY-MM-DD"),

      datetime_menu: false,

      selected_date: false,
      selected_camera: null,

      selected_table: 0,

      options: {
        sortBy: ["media_timestamp"],
        sortDesc: [true],
        limit: 100,
        offset: 0,
      },

      fullscreen_img: false,

      selected_image_index: false,
      selected_customer: "All Customers",

      contraction_1_filter: "All",
      contraction_2_filter: "All",
      valid_event_filter: "All",

      analytics_datetime_menu: false,
      analytics_start_date: moment().subtract(21, "day").format("YYYY-MM-DD"),
    };
  },
  created() {
    this.$store.dispatch("DDB_GET_CALVING_CHARTS", {
      date_start: this.analytics_start_date,
      date_end: this.todays_date,
      customer: null,
    });
    eventBus.$on("nextImageCalving", (index) => {
      index = index + 1;
      if (index >= this.calving_alaytics.length) {
        index = 0;
      }
      let item = this.calving_alaytics[index];
      var params = {
        customer_information:
          item.customer.first_name + " " + item.customer.last_name,
        img: item,
        isAnalytics: true,
        sourceComponent: "CalvingAnalytics",
        image_path: item.s3_uri
          .replace("_event.jpg", "")
          .replace("_annotated", ""),
        camera_id: item.camera_id,
        image_index: index,
        imagesLength: this.calving_alaytics.length,
        image_time: this.convertTo12HourFormat(item.media_timestamp),
        bbox_list:
          item.bbox &&
          item.species_label !== undefined &&
          item.species_conf !== undefined
            ? [
                {
                  x1: item.bbox?.x,
                  y1: item.bbox?.y,
                  w: item.bbox?.w,
                  h: item.bbox?.h,
                  species: item.species_label,
                  speciesConf: item.species_conf,
                },
              ]
            : undefined,
        body_parts_list: [
          ...(item.tail_bbox && item.tail_conf !== undefined
            ? [{ bbox: item.tail_bbox, conf: item.tail_conf, label: "tail" }]
            : []),
          ...(item.tag_1_bbox && item.tag_1_conf !== undefined
            ? [{ bbox: item.tag_1_bbox, conf: item.tag_1_conf, label: "tag" }]
            : []),
          ...(item.tag_2_bbox && item.tag_2_conf !== undefined
            ? [{ bbox: item.tag_2_bbox, conf: item.tag_2_conf, label: "tag" }]
            : []),
        ],

        classificationInfo:
          item.contraction_label !== undefined &&
          item.contraction_conf !== undefined &&
          item.contraction_label_2 !== undefined &&
          item.contraction_conf_2 !== undefined
            ? [
                {
                  label1: item.contraction_label,
                  conf1: item.contraction_conf,
                  label2: item.contraction_label_2,
                  conf2: item.contraction_conf_2,
                },
              ]
            : undefined,
      };
      eventBus.$emit("open_dialog_images", params);
    });
    eventBus.$on("previousImageCalving", (index) => {
      index = index - 1;
      if (index < 0) {
        index = this.calving_alaytics.length - 1;
      }
      let item = this.calving_alaytics[index];
      var params = {
        customer_information:
          item.customer.first_name + " " + item.customer.last_name,
        img: item,
        isAnalytics: true,
        sourceComponent: "CalvingAnalytics",
        image_path: item.s3_uri
          .replace("_event.jpg", "")
          .replace("_annotated", ""),
        camera_id: item.camera_id,
        image_index: index,
        imagesLength: this.calving_alaytics.length,
        image_time: this.convertTo12HourFormat(item.media_timestamp),
        bbox_list:
          item.bbox &&
          item.species_label !== undefined &&
          item.species_conf !== undefined
            ? [
                {
                  x1: item.bbox?.x,
                  y1: item.bbox?.y,
                  w: item.bbox?.w,
                  h: item.bbox?.h,
                  species: item.species_label,
                  speciesConf: item.species_conf,
                },
              ]
            : undefined,
        body_parts_list: [
          ...(item.tail_bbox && item.tail_conf !== undefined
            ? [{ bbox: item.tail_bbox, conf: item.tail_conf, label: "tail" }]
            : []),
          ...(item.tag_1_bbox && item.tag_1_conf !== undefined
            ? [{ bbox: item.tag_1_bbox, conf: item.tag_1_conf, label: "tag" }]
            : []),
          ...(item.tag_2_bbox && item.tag_2_conf !== undefined
            ? [{ bbox: item.tag_2_bbox, conf: item.tag_2_conf, label: "tag" }]
            : []),
        ],

        classificationInfo:
          item.contraction_label !== undefined &&
          item.contraction_conf !== undefined &&
          item.contraction_label_2 !== undefined &&
          item.contraction_conf_2 !== undefined
            ? [
                {
                  label1: item.contraction_label,
                  conf1: item.contraction_conf,
                  label2: item.contraction_label_2,
                  conf2: item.contraction_conf_2,
                },
              ]
            : undefined,
      };
      eventBus.$emit("open_dialog_images", params);
    });
  },
  methods: {
    convertTo12HourFormat(timeString) {
      const date = new Date(timeString);

      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-based
      const day = date.getDate().toString().padStart(2, "0");
      let hours = date.getHours();
      const minutes = date.getMinutes().toString().padStart(2, "0");
      const seconds = date.getSeconds().toString().padStart(2, "0");
      const ampm = hours >= 12 ? "PM" : "AM";

      hours = hours % 12;

      hours = hours ? hours : 12;

      const hoursStr = hours.toString().padStart(2, "0");

      return `${year}-${month}-${day} ${hoursStr}:${minutes}:${seconds} ${ampm}`;
    },
    open_image(item, index) {
      var params = {
        img: item,
        isAnalytics: true,
        customer_information:
          item.customer.first_name + " " + item.customer.last_name,
        image_path: item.s3_uri
          .replace("_event.jpg", "")
          .replace("_annotated", ""),
        camera_id: item.camera_id,
        image_index: index,
        sourceComponent: "CalvingAnalytics",
        imagesLength: this.calving_alaytics.length,
        image_time: this.convertTo12HourFormat(item.media_timestamp),
        bbox_list:
          item.bbox &&
          item.species_label !== undefined &&
          item.species_conf !== undefined
            ? [
                {
                  x1: item.bbox?.x,
                  y1: item.bbox?.y,
                  w: item.bbox?.w,
                  h: item.bbox?.h,
                  species: item.species_label,
                  speciesConf: item.species_conf,
                },
              ]
            : undefined,
        body_parts_list: [
          ...(item.tail_bbox && item.tail_conf !== undefined
            ? [{ bbox: item.tail_bbox, conf: item.tail_conf, label: "tail" }]
            : []),
          ...(item.tag_1_bbox && item.tag_1_conf !== undefined
            ? [{ bbox: item.tag_1_bbox, conf: item.tag_1_conf, label: "tag" }]
            : []),
          ...(item.tag_2_bbox && item.tag_2_conf !== undefined
            ? [{ bbox: item.tag_2_bbox, conf: item.tag_2_conf, label: "tag" }]
            : []),
        ],

        classificationInfo:
          item.contraction_label !== undefined &&
          item.contraction_conf !== undefined &&
          item.contraction_label_2 !== undefined &&
          item.contraction_conf_2 !== undefined
            ? [
                {
                  label1: item.contraction_label,
                  conf1: item.contraction_conf,
                  label2: item.contraction_label_2,
                  conf2: item.contraction_conf_2,
                },
              ]
            : undefined,
      };
      eventBus.$emit("open_dialog_images", params);
    },
    refresh() {
      this.loading = true;
      this.$store
        .dispatch("DDB_GET_ANALYTICS_CALVING", this.query_params)
        .then(() => {
          this.loading = false;
        });
    },

    num_days(date) {
      let days = moment().diff(moment(date), "days");
      return days + " day" + (days > 1 ? "s" : "");
    },
  },

  computed: {
    analytics_data() {
      let type = this.$store.getters.getCalvingCharts.type;
      let customer = this.$store.getters.getCalvingCharts.customer;
      let sent = this.$store.getters.getCalvingCharts.sent;
      var labels = [];
      var datasets_type = [
        {
          data: [],
          backgroundColor: "#388E3C",
          borderColor: "#fff",
          label: "Tail Up",
        },
        {
          data: [],
          backgroundColor: "#F57C00",
          borderColor: "#fff",
          label: "Tail Down",
        },
      ];
      var datasets_customer = [
        {
          data: [],
          backgroundColor: "#42A5F5",
          borderColor: "#fff",
          label: "",
        },
        {
          data: [],
          backgroundColor: "#388E3C",
          borderColor: "#fff",
          label: "",
        },
        {
          data: [],
          backgroundColor: "#F57C00",
          borderColor: "#fff",
          label: "",
        },
        {
          data: [],
          backgroundColor: "#FBC02D",
          borderColor: "#fff",
          label: "",
        },
        {
          data: [],
          backgroundColor: "#ae4773",
          borderColor: "#fff",
          label: "",
        },
      ];
      var datasets_sent = [
        {
          data: [],
          backgroundColor: "#42A5F5",
          borderColor: "#fff",
          label: "GPT",
        },
        {
          data: [],
          backgroundColor: "#388E3C",
          borderColor: "#fff",
          label: "History",
        },
      ];
      if (type.length) {
        for (const i in type) {
          labels.push(type[i].date);
          datasets_type[0].data.push(type[i].tail_up);
          datasets_type[1].data.push(type[i].tail_down);

          if (customer[i]) {
            datasets_customer[0].data.push(customer[i].customer1_count);
            datasets_customer[1].data.push(customer[i].customer2_count);
            datasets_customer[2].data.push(customer[i].customer3_count);
            datasets_customer[3].data.push(customer[i].customer4_count);
            datasets_customer[4].data.push(customer[i].customer5_count);
            datasets_customer[0].label =
              this.$store.getters.getCustomerNames[1][
                customer[i].customer1_name
              ][0].text;
            datasets_customer[1].label =
              this.$store.getters.getCustomerNames[1][
                customer[i].customer2_name
              ][0].text;
            datasets_customer[2].label =
              this.$store.getters.getCustomerNames[1][
                customer[i].customer3_name
              ][0].text;
            datasets_customer[3].label =
              this.$store.getters.getCustomerNames[1][
                customer[i].customer4_name
              ][0].text;
            datasets_customer[4].label =
              this.$store.getters.getCustomerNames[1][
                customer[i].customer5_name
              ][0].text;
          }

          datasets_sent[0].data.push(sent[i].gpt_check);
          datasets_sent[1].data.push(sent[i].history_check);
        }

        return {
          datasets_type: datasets_type,
          datasets_customer: datasets_customer,
          datasets_sent: datasets_sent,
          labels: labels,
        };
      }
      return false;
    },
    calving_alaytic_count() {
      return this.$store.getters.getCalvingAnalyticsCount;
    },

    sort_direction() {
      return this.options.sortDesc && this.options.sortDesc[0] ? "desc" : "asc";
    },
    query_params() {
      return {
        sort: this.options.sortBy ? this.options.sortBy[0] : "datetime",
        sort_direction: this.sort_direction,
        limit: this.options.itemsPerPage,
        offset: (this.options.page - 1) * this.options.itemsPerPage,
        date: this.selected_date,
        filter: {
          camera: this.selected_camera,
          customer_id: this.selected_customer,
          contraction_1_filter: this.contraction_1_filter,
          contraction_2_filter: this.contraction_2_filter,
          valid_event_filter: this.valid_event_filter,
        },
      };
    },
    camera_names() {
      var cams = ["All"];
      for (const i in this.$store.getters.getCameraObjects) {
        cams.push({
          text: this.$store.getters.getCameraObjects[i].name,
          value: this.$store.getters.getCameraObjects[i].id,
        });
      }
      return cams;
    },
    cookie_bucket() {
      return process.env.VUE_APP_COOKIE_BUCKET_FOOTAGE;
    },

    headers_general() {
      return [
        { text: "", value: "image", width: "200px" },
        {
          text: "Timestamp",
          value: "media_timestamp",
          width: "180px",
          sort: () => {
            return false;
          },
        },
        {
          text: "Age",
          value: "age",
          width: "80px",
          sortable: false,
        },
        {
          text: "Camera Name",
          value: "camera_id",
          width: "240px",
          cellClass: "no_edit",
        },
        // { text: "Camera ID", value: "camera.id", width: "180px" },
        {
          text: "Customer",
          value: "customer_id",
          width: "220px",
          sortable: false,
        },
        {
          text: "Contraction 1",
          value: "contraction_label",
          width: "200px",
        },
        {
          text: "Contraction 2",
          value: "contraction_label_2",
          width: "200px",
        },
        {
          text: "Valid Event",
          value: "valid_calving_event",
          width: "200px",
        },
        {
          text: "Processing Time",
          value: "total_processing_time",
        },
      ];
    },

    headers_annotations() {
      return [
        { text: "", value: "image", width: "200px" },
        {
          text: "Timestamp",
          value: "media_timestamp",
          width: "180px",
          sort: () => {
            return false;
          },
        },
        {
          text: "Age",
          value: "age",
          width: "80px",
          sortable: false,
        },
        {
          text: "Camera Name",
          value: "camera_id",
          width: "240px",
          cellClass: "no_edit",
        },
        // { text: "Camera ID", value: "camera.id", width: "180px" },
        {
          text: "Customer",
          value: "customer_id",
          width: "220px",
          sortable: false,
        },
        {
          text: "Tail Area",
          value: "tail_area",
        },
        {
          text: "Tail Conf",
          value: "tail_conf",
        },
        {
          text: "Tail Ratio",
          value: "tail_ratio",
        },
        {
          text: "BBox Area",
          value: "bbox_area",
        },
        {
          text: "BBox Ratio",
          value: "bbox_ratio",
        },
        {
          text: "Species",
          value: "species_label",
        },
        {
          text: "Species Conf.",
          value: "species_conf",
        },
      ];
    },
    headers_checks() {
      return [
        { text: "", value: "image", width: "200px" },
        {
          text: "Timestamp",
          value: "media_timestamp",
          width: "180px",
          sort: () => {
            return false;
          },
        },
        {
          text: "Age",
          value: "age",
          width: "80px",
          sortable: false,
        },
        {
          text: "Camera Name",
          value: "camera_id",
          width: "240px",
          cellClass: "no_edit",
        },
        // { text: "Camera ID", value: "camera.id", width: "180px" },
        {
          text: "Customer",
          value: "customer_id",
          width: "220px",
          sortable: false,
        },
        {
          text: "History Check",
          value: "history_check",
        },
        {
          text: "GPT Check",
          value: "gpt_check",
        },
        {
          text: "Group Filter",
          value: "group_filter",
        },
      ];
    },
    headers_sms() {
      return [
        { text: "", value: "image", width: "200px" },
        {
          text: "Timestamp",
          value: "media_timestamp",
          width: "180px",
          sort: () => {
            return false;
          },
        },
        {
          text: "Age",
          value: "age",
          width: "80px",
          sortable: false,
        },
        {
          text: "Camera Name",
          value: "camera_id",
          width: "240px",
          cellClass: "no_edit",
        },
        // { text: "Camera ID", value: "camera.id", width: "180px" },
        {
          text: "Customer",
          value: "customer_id",
          width: "220px",
          sortable: false,
        },
        {
          text: "Label",
          value: "calving_label",
          width: "180px",
        },
        {
          text: "Description",
          value: "calving_description",
        },
      ];
    },

    selected_header() {
      if (this.selected_table == 0) {
        return this.headers_general;
      } else if (this.selected_table == 1) {
        return this.headers_annotations;
      } else if (this.selected_table == 2) {
        return this.headers_checks;
      } else if (this.selected_table == 3) {
        return this.headers_sms;
      }
      return this.headers_general;
    },

    calving_alaytics() {
      return this.$store.getters.getCalvingAnalytics
        ? this.$store.getters.getCalvingAnalytics
        : [];
    },
  },

  watch: {
    analytics_start_date() {
      this.$store.dispatch("DDB_GET_CALVING_CHARTS", {
        date_start: this.analytics_start_date,
        date_end: this.todays_date,
        customer:
          this.selected_customer != "All Customers"
            ? this.selected_customer
            : null,
      });
    },
    selected_customer() {
      this.$store.dispatch("DDB_GET_CALVING_CHARTS", {
        date_start: this.analytics_start_date,
        date_end: this.todays_date,
        customer:
          this.selected_customer != "All Customers"
            ? this.selected_customer
            : null,
      });
    },
    date() {
      if (!this.date) {
        this.selected_date = null;
      } else {
        var selected_date = moment(this.date, "YYYY-MM-DDTHH").format(
          "YYYY-MM-DDTHH"
        );
        if (selected_date.slice(8, 10) != this.date.slice(8, 10)) {
          selected_date = moment(selected_date, "YYYY-MM-DDTHH")
            .add(1, "days")
            .format("YYYY-MM-DDTHH");
        }
        this.selected_date = selected_date;
      }
    },

    query_params() {
      if (this.query_params) {
        this.loading = true;
        this.$store
          .dispatch("DDB_GET_ANALYTICS_CALVING", this.query_params)
          .then(() => {
            this.loading = false;
          });
      }
    },

    dialog() {
      if (this.dialog == false) {
        this.owner_name = false;
        this.create_new = false;
      }
    },
  },
};
</script>

<style lang="css" scoped>
.calving_alaytics_img:hover {
  transform: scale(1.4);
  z-index: 500;
}
</style>
